.handleSmDashHead {
    background: radial-gradient(50% 50.00% at 50% 50.00%, #2D37A6 0%, #161958 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 5px 15px;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    background: radial-gradient(50% 50.00% at 50% 50.00%, #2D37A6 0%, #161958 100%) !important;
    color: white;
}

.css-1ab2xsx {
    background: radial-gradient(50% 50.00% at 50% 50.00%, #2D37A6 0%, #161958 100%) !important;
    color: white;
}