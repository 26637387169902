.box-image2 {
    border-radius: 3px;
    border: 1px solid #5B5B5B;
    box-shadow: 10px 10px 30px 9px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 300px;
    flex-direction: column;
}

.box-image2 p {
    color: #000;
    font-family: Lexend;
    font-size: 20px;
    margin-bottom: 0;
    padding-top: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cardDocs {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 23px;
    border: none;
    /* border: 1px solid rgba(91, 91, 91, 0.50); */
    background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
    box-shadow: 10px 10px 33px 0px rgba(91, 91, 91, 0.20);
}

.gapFlex {
    gap: 15px;
    margin-bottom: 10px;
}

/* .cardCertificate {
    width: fit-content;
    height: auto;
    flex-shrink: 0;
    border-radius: 23px;
    border: 1px solid rgba(91, 91, 91, 0.50);
    background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
    box-shadow: 10px 10px 33px 0px rgba(91, 91, 91, 0.20);
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 80px;
}



.handleTitleC {
    color: #000;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.handleDesC {
    color: #5B5B5B;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
} */

@media only screen and (max-width: 767px) {
    .cardDocs {
        width: 100%;
        height: auto;
        flex-shrink: 0;
        border-radius: 23px;
        border: 1px solid rgba(91, 91, 91, 0.50);
        background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
        box-shadow: 10px 10px 33px 0px rgba(91, 91, 91, 0.20);
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-direction: column;
        gap: 10px;
    }
}