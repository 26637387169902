.btn-up-photo {
    padding: 8px;
    border-radius: 8px;
    background: #D6DBFB;
    border: none;
    outline: none;

    color: #2A339B;
    font-family: Lexend;
    /* font-size: 24px; */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.btn-up-photo:hover {
    background: #cdd3fc;
}

.btn-up-photo:disabled {
    background: #bdc1e0;
}

.img-ring {
    border: 15px solid #D6DBFB;
    margin-bottom: 10px;
}

.boldText {
    color: #000;
    font-family: Lexend;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pTitle p {
    color: #2A339B;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pDetails p {
    color: #A6A6A6;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 550px) {
    .pTitle {
        font-size: 18px;
    }

    .pDetails {
        font-size: 18px;
    }
}