.explorerShowDiv {
    border-radius: 4px;
    padding: 4px 6px;
    background: #F1F1F1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    overflow: hidden;
}

.explorerShowDiv div p {
    color: #545454;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

@media only screen and (max-width:767px) {
    .explorerShowDiv div p {
        font-size: 8px;
    }
}