.Certificates {
    background-color: aquamarine;
}

.custom-style h4 {
    color: #000;
    font-family: Lexend;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.custom-style p {
    text-align: justify;
    padding-top: 22px;
    color: #545454;
    font-family: Saira;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.handleImgOurProd2 {
    width: 75%;
}

/* .content {
    background-image: url(/public/assets/images/top.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 100px;
    padding-top: 0px;
    box-sizing: border-box;
    text-align: left;
} */

.content h2 {
    color: #024F83;
    font-family: Lexend;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content p {
    color: #000;
    font-family: Lexend;
    font-size: 45px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    /* width: 550px; */
}

.content h3 {
    color: #545454;
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 768px) {


    .flex-handle-content {
        padding-top: 20px;
        gap: 30px;
        /* padding-bottom: 20px; */
    }

    .content h2 {
        font-size: 20px;
    }

    .content p {
        font-size: 24px;
    }

    .content h3 {
        font-size: 14px;
    }

    .handleImgOurProd2 {
        width: 100%;
    }

    .content h3 {
        /* word-wrap: break-word;
        width: 11em;
        text-align: initial; */
    }
}



.top-button {
    display: inline-block;
    border-radius: 20px;
    background-color: #FCB927;
    padding: 10px 20px;
}

.top-button button {
    border: none;
    background: none;
    color: #262626;
    font-size: 20px;
}

.top2 {
    background-image: url(/public/assets/images/coverPaper.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    /* border: 1px solid red; */
    width: 100%;
}


.top2 .content1 {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    column-gap: 30px;
    padding-top: 10px;
    padding-bottom: 40px;
    word-spacing: 2px;
    margin-bottom: 20px;
    text-align: justify;
    font-size: 18px;

}

.top2 .content1 h4 {
    font-size: 26px;
    font-weight: bold;
    word-spacing: 5px;
    text-align: center;
    color: black;
    transition: color 0.3s;
}





.custom-style {
    border-radius: 26px;
    background: #D6DBFB;
    padding: 20px;
    height: auto;
    width: 100%;
    text-align: justify;
    /* transition: background-color 0.2s; */
    color: #5B5B5B;
    position: relative;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
    overflow: hidden;
}

.custom-style::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
    color: white !important;
    background-color: #1F4075 !important;
    background-image: url(/public/assets/images/blend-zig-zag.png) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    z-index: -1;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);

}

.custom-style:hover {
    color: white !important;
}

.custom-style:hover::before {
    height: 100%;
}


/* .top2 .content1 .custom-style:hover {
    background-color: #1F4075;
    color: white;
} */

@media (max-width: 768px) {
    .top2 .content1 {
        padding: 10px;
    }

    .top2 .content1 h2 {
        font-size: 24px;
    }

    .top2 .content1 h4 {
        font-size: 20px;
    }

    .top2 .content1 p {
        font-size: 16px;
    }

    .top2 .content1 {
        flex-wrap: wrap;
        row-gap: 30px;
    }

    .reduceMarginForSm {
        margin-top: 0px !important;
    }
}





.image-container {
    background-image: url(/public/assets/images/last.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-bottom: 70%;
}

/* 
.last {
    text-align: center;
} */

.last div {
    text-align: center;
}

.addSome {
    word-spacing: 5px;
}

.last div p {
    color: #5B5B5B;
    text-align: center;
    font-family: 'Saira', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}