.handleTitleQRC {
    color: #000;
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
}

.handleDesQRC {
    color: #5B5B5B;
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
}