.cardCertificate {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 23px;
    border: none;
    /* border: 1px solid rgba(91, 91, 91, 0.50); */
    background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
    box-shadow: 10px 10px 33px 0px rgba(91, 91, 91, 0.20);
    /* display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 0px; */
}

.box-image {
    border-radius: 3px;
    /* border: 1px solid #5B5B5B; */
    box-shadow: 10px 10px 30px 9px rgba(0, 0, 0, 0.10);
}

.handleTitleC {
    color: #000;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.handleDesC {
    color: #5B5B5B;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.handleScanner {
    position: relative;
    left: 10px;
}

@media only screen and (min-width: 993px) {
    .cardCertificate {
        min-width: 60%;
        width: fit-content;
        height: auto;
        flex-shrink: 0;
        border-radius: 23px;
        border: none;
        /* border: 1px solid rgba(91, 91, 91, 0.50); */
        background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
        box-shadow: 10px 10px 33px 0px rgba(91, 91, 91, 0.20);
        /* display: flex;
        justify-content: space-between;
        align-items: start;
        gap: 80px; */
        margin-left: auto;
        margin-right: auto;
    }

    .handleScanner {
        position: relative;
        left: 0px;
    }
}

@media only screen and (max-width: 370px) {
    .sizeCardTitle {
        font-size: 14px !important;
    }
}

@media only screen and (min-width:371px) and (max-width: 460px) {
    .sizeCardTitle {
        font-size: 16px !important;
    }
}