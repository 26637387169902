.container-our-docs {
    background-image: url(/public/assets/images/our-documents-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 100px;
    box-sizing: border-box;
    text-align: left;
}

.Certificates {
    background-color: aquamarine;
}

/* .custom-style p {
    text-align: center;
} */

.handleImgOurProd2 {
    width: 75%;
}



/* .container-our-docs h2 {
    color: #024F83;
    font-family: Lexend;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.container-our-docs p {
    color: #000;
    font-family: Lexend;
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.container-our-docs h3 {
    color: #5B5B5B;
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 768px) {
    .container-our-docs {
        padding: 30px;
    }

    .container-our-docs h2 {
        font-size: 20px;
    }

    .container-our-docs p {
        font-size: 24px;
    }

    .container-our-docs h3 {
        font-size: 14px;
    }

    .handleImgOurProd2 {
        width: 100%;
    }
}


@media screen and (max-width: 768px) {

    .container-our-docs h3 {
        word-wrap: break-word;
        width: 11em;
        text-align: initial;
    }


}



.top-button {
    display: inline-block;
    border-radius: 20px;
    background-color: #FCB927;
    padding: 10px 20px;
}

.top-button button {
    border: none;
    background: none;
    color: #262626;
    font-size: 20px;
}




.top2 .content1 {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    column-gap: 30px;
    padding-top: 10px;
    padding-bottom: 40px;
    word-spacing: 2px;
    margin-bottom: 20px;
    text-align: justify;
    font-size: 18px;

}

.top2 .content1 h4 {
    font-size: 26px;
    font-weight: bold;
    word-spacing: 5px;
    text-align: center;
    color: black;
    transition: color 0.3s;
}





.top2 .content1 .custom-style {
    background-color: #DCF1FF;
    border-radius: 19px;
    padding: 20px;
    height: auto;
    width: 100%;
    text-align: justify;
    transition: background-color 0.3s;
    color: #5B5B5B;
}

.top2 .content1 .custom-style:hover {
    background-color: #1F4075;
    color: white;
}

@media (max-width: 768px) {
    .top2 .content1 {
        padding: 10px;
    }

    .top2 .content1 h2 {
        font-size: 24px;
    }

    .top2 .content1 h4 {
        font-size: 20px;
    }

    .top2 .content1 p {
        font-size: 16px;
    }

    .top2 .content1 {
        flex-wrap: wrap;
        row-gap: 30px;
    }
}





.image-container {
    background-image: url(/public/assets/images/docsLast.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-bottom: 70%;
}


.last {
    text-align: center;
}

.last h2 {
    word-spacing: 5px;
}

.last p {
    color: #5B5B5B;
    text-align: center;
    font-family: 'Saira', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
} */