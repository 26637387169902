.trans {
    /* transition: transform .5s ease; */
    /* transition-delay: 1s; */
    /* overflow: hidden; */
}

.trans:hover {
    /* transform: scale(1.3); */
}

.width-mode {
    width: 150px;
}

@media only screen and (max-width:500px) {
    .width-mode {
        width: auto;
        /* padding-left: 16px !important; */
        /* padding-right: 16px !important; */
    }
}