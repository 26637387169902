.search-bar-dashboard {
    width: 100%;
    height: 40px;
    padding: 10px;
    border-radius: 48px;
    background: #D6DBFB;
    border: none;
    outline: none;
}

.search-container {
    position: relative;
    display: inline-block;
    text-align: end;
    z-index: 0;
}

.search-container input[type="text"] {
    /* padding-left: 15px; */
    /* border: 1px solid red; */
    padding-right: 35px;
    font-family: Lexend;
    /* Adjust the padding to accommodate the search icon */
}

.search-container i {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    font-size: 18px;
    color: black;
    background: #D6DBFB;
    z-index: 1500;
    /* Adjust the font size as needed */
}

@media only screen and (max-width:992px) {
    .search-bar-dashboard {
        width: 95%;
        height: 40px;
        padding: 10px;
        border-radius: 48px;
        background: #D6DBFB;
        border: none;
        outline: none;
    }

    .search-container {
        position: relative;
        display: inline-block;
        z-index: 0;
    }

}