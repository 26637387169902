.bg-banner {
  height: 85vh;
  width: 100%;
  background-image: url(/public/assets/images/main-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.containerHeightBg {
  min-height: 65vh;
}

.marquee-container {
  white-space: nowrap;
  overflow: hidden;
}

.marquee-content {
  display: inline-block;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.titleDes {
  color: #fff;
  font-family: Lexend;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
}

.titleDes span {
  color: #cdb1f0;
}

/* .py-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
      margin-left: 30px;
      margin-right: 30px;
  } */

.subDes {
  color: #aeabab;
  font-family: "Saira", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.boxInnerBannerRight {
  width: 230px;
  height: 84px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  /* margin-top: -200px; */
  position: relative;
  top: -215px;
  left: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
}

.boxInnerBannerLeft {
  width: 230px;
  height: 84px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  /* margin-top: -200px; */
  position: relative;
  top: -215px;
  left: -50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
}

.boxBannerText {
  color: #000;
  font-family: "Saira", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 3px;
}

.btn-g-1 {
  display: inline-flex;
  padding: 5px 8px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: none;
  background: #f2f2f2;
  color: #828282;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.btn-g-2 {
  display: inline-flex;
  padding: 5px 8px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #ffeff1;
  color: #fd5b71;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  border: none;
}

.btn-g-3 {
  display: inline-flex;
  padding: 5px 8px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #f5eefc;
  color: #9b51e0;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  border: none;
}

.featured-box {
  width: 320px;
  height: 350px;
  flex-shrink: 0;
  background: #dcf1ff;
  text-align: center;
}

.featured-box h4 {
  color: #000;
  font-family: Lexend;
  /* font-size: 32px; */
  font-style: normal;
  font-weight: 700;
  /* line-height: normal; */
}

.featured-box p {
  color: #363434;
  text-align: center;
  font-family: "Saira", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  padding: 20px 0px;
}

@media only screen and (max-width: 700px) {
  .smPosition {
    position: relative;
    top: -180px;
  }
}

.web-preview-style {
  width: 100%;
  height: 32rem;
  padding-top: 22%;
  background-image: url(/public/assets/images/web-preview.png);
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 700px) {
  .web-preview-style {
    width: 100%;
    height: 12rem;
    margin-top: 13%;
    background-image: url(/public/assets/images/web-preview.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.coverDiv {
  backdrop-filter: blur(50px);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* border: 1px solid #000; */
}

.product-modules {
  height: auto;
  width: auto;
  /* padding-left: 50px;
    padding-right: 50px; */
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  /* position: absolute;
    bottom: 10%; */
}

@media (max-width: 700px) {
  .product-modules {
    height: auto;
    width: auto;
    /* padding-left: 50px;
        padding-right: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    /* flex-direction: column; */
    /* gap: 10px; */
    margin-top: 20px;
  }
}

.product-modules-details {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #f3f4fb;
  font-family: "Saira", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 105.263% */
}

.headings {
  color: #000;
  text-align: center;
  font-family: Lexend;
  /* font-size: 44px; */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 15px;
}

.sub-heading {
  padding-top: 13px;
  color: #000;
  font-family: Lexend;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sub-heading-content {
  padding: 30px;
  color: #5b5b5b;
  text-align: center;
  font-family: "Saira", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sub-heading :hover {
  color: white;
}

.sub-heading-content:hover {
  color: white;
}

.div-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}

@media only screen and (min-width: 770px) and (max-width: 992px) {
  .div-container {
    flex-direction: column;
    gap: 25px;
  }

  .div-style {
    width: 70% !important;
    padding: 1%;
  }
}

@media (max-width: 800px) {
  .div-container {
    flex-direction: column;
    gap: 25px;
  }

  .div-style {
    width: 100% !important;
    padding: 1%;
  }

  .sub-heading :hover {
    color: white;
  }

  .sub-heading-content:hover {
    color: white;
  }

  .div-style::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
    color: white;
    background-image: url(/public/assets/images/blend-zig-zag.png) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background: #161958;
    z-index: -1;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  }

  .div-style:hover::before {
    height: 100%;
  }
}

.feature-content-heading-style {
  color: #000;
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  text-align: center;
  padding-top: 12px;
}

.feature-content-style {
  color: #545454;
  text-align: justify;
  font-family: "Saira", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding: 0px 15px;
}

.div-style {
  height: 20rem;
  width: 100%;
  border-radius: 50px;
  background-image: url(/public/assets/images/blend-zig-zag.png);
  background-position: center;
  background-repeat: no-repeat;
  background-color: #d6dbfb;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  position: relative;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  /* cursor: pointer; */
}

.div-style::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  color: white;
  background-image: url(/public/assets/images/blend-zig-zag.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background: #161958;
  z-index: -1;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.div-style:hover::before {
  height: 100%;
}

.div-style:hover {
  /* color: white;
    background-color: #161958;
    transition-duration: .2s; */
  box-shadow: none;
}

.feautre-types {
  margin-top: 37px;
  text-align: center;
  display: flex;
  gap: 18%;
  color: #000;
  font-family: "Lexend", sans-serif;
  font-size: 24px;
  font-style: normal;
  margin-right: 8%;
  font-weight: 700;
  line-height: normal;
  justify-content: end;
}


@media only screen and (max-width: 600px) {
  .feautre-types {
    margin-top: 37px;
    text-align: center;
    display: flex;
    gap: 1%;
    color: #000;
    font-family: "Lexend", sans-serif;
    font-size: 24px;
    font-style: normal;
    margin-right: 8%;
    font-weight: 700;
    line-height: normal;
    justify-content: space-between;
  }


}

.set-feature-margin-1 {
  margin-right: 20%;
}

.set-feature-margin-2 {
  margin-right: 21%;
}

.set-feature-margin-3 {
  margin-right: 5%;
}

.subHeading {
  color: #545454;
  text-align: center;
  font-family: "Saira", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.first-feature-row {
  margin-top: 16px;
  display: flex;
  margin-left: 4%;
  margin-right: 4%;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}

.remainig-feature-row {
  margin-top: 32px;
  display: flex;
  margin-left: 4%;
  margin-right: 4%;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}


.set-polygon-1 {
  height: 6.25rem;
  width: 17rem;
  z-index: -1;
  background-image: url(/public/assets/images/feature-polygon-1.png);
  background-repeat: no-repeat;
}

.set-polygon-2 {
  height: 6.25rem;
  width: 17rem;
  z-index: -1;
  background-image: url(/public/assets/images/feature-polygon-2.png);
  background-repeat: no-repeat;
}

.set-polygon-3 {
  height: 6.25rem;
  width: 17rem;
  z-index: -1;
  background-image: url(/public/assets/images/feature-polygon-3.png);
  background-repeat: no-repeat;
}

.set-polygon-4 {
  height: 6.25rem;
  width: 17rem;
  z-index: -1;
  background-image: url(/public/assets/images/feature-polygon-4.png);
  background-repeat: no-repeat;
}

.set-zigzag {
  margin-top: 7%;
  height: 3.813rem;
  width: 16.956rem;
  background-image: url(/public/assets/images/arr.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 9%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-title-style {
  text-align: center;
  justify-content: center;
  /* padding-top: 5%; */
  margin-bottom: 0px;
  color: #000;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subtitle-features {
  /* margin-top: 1%; */
  height: 4rem;
  width: 17rem;
  background-image: url(/public/assets/images/arr2.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.feature-subtitle-style {
  color: #000;
  font-family: Lexend;
  font-size: 24px;
  justify-content: center;
  text-align: center;
  /* padding-top: 4%; */

  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reason-tile-list {
  margin-top: 66px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
}

.scroll-bg {
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 650px) {
  .feature-subtitle-style {

    font-size: 18px;

  }

  .feature-heading-style {
    font-size: 20px !important;
  }

  .scroll-bg {
    width: auto;
    overflow-x: scroll;
    margin-right: 7%;
  }

  .feature-title-style {
    font-size: 18px;
  }

  .set-zigzag {
    margin-top: 14.5%;
    height: 3rem;
    width: 10.956rem;
    margin-left: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subtitle-features {
    height: 3rem;
    width: 100%;
  }

  .remainig-feature-row {
    width: 700px;
  }

  .first-feature-row {
    width: 700px;
  }

}

@media only screen and (min-width: 651px) and (max-width: 991px) {
  .feature-subtitle-style {

    font-size: 18px;

  }

  .feature-title-style {
    font-size: 18px;
  }

  .set-zigzag {
    margin-top: 14.5%;
    height: 3rem;
    width: 10.956rem;
    margin-left: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subtitle-features {
    height: 3rem;
  }
}

@media only screen and (max-width: 800px) {
  .reason-tile-list {
    margin-top: 66px;
    flex-direction: column;
  }

  .reason-tile-details {
    height: 550px;
    width: 100%;
    margin-top: 30px;
    border-radius: 31px;
    background-color: #161958;
    z-index: -1;
  }


}

.reason-tile-details {
  height: auto;
  width: 50%;
  border-radius: 31px;
  background-color: #161958;
  z-index: -1;
}

.reason-tile-details-board {
  height: auto;
  width: 95%;
  border-radius: 31px;
  padding: 5%;
  transform: rotate(-6.392deg);
  margin: 20px;
  border-radius: 31px;
  border: 1px solid #024f83;
  background-color: white;
}

@media (max-width: 800px) {
  .reason-tile-details-board {
    height: auto;
    width: 85%;
    border-radius: 31px;
    transform: rotate(-6.392deg);
    margin: 20px;
    border-radius: 31px;
    border: 1px solid #024f83;
    background-color: white;
  }
}

.reason-checked {
  color: #000;
  text-align: start;
  font-family: Lexend;
  font-size: 27px;
  margin-top: 0px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  line-height: normal;
}

.featured-bg {
  height: 50%;
  width: 100%;

  background-size: cover;
}

@media (max-width: 800px) {
  .reason-checked {
    width: 100%;
    text-align: start;
    font-family: Lexend;
    font-size: 20px;
    margin-top: 0px;
    margin-left: 10px;
    padding: 20px;
    font-style: normal;
    display: block;
    font-weight: 400;
    line-height: normal;
  }
}

.reason-checked-clicked {
  transition: all 0.5s ease-out;
  transition-property: background-color;
  background-image: url(/public/assets/images/right_chevron.png);
  filter: drop-shadow(1px 1px 30px rgba(2, 79, 131, 0.29));
  background-position: right center;
  background-repeat: no-repeat;
  background-color: #e5e9ff;
}

@media (max-width: 700px) {
  .reason-checked-clicked {
    transition: all 0.5s ease-out;
    transition-property: background-color;
    background-image: url(/public/assets/images/right_chevron.png);
    filter: drop-shadow(1px 1px 30px rgba(2, 79, 131, 0.29));
    background-size: 18px;
    /* font-size: 16px; */
    text-align: left;
    background-position: right center;
    background-repeat: no-repeat;
    background-color: #e5e9ff;
  }
}

.mind-map {
  display: flex;
  justify-content: center;
  text-align: center;
  transform: rotate(5deg);
}

@media (max-width: 800px) {
  .mind-map {
    display: flex;
    justify-content: center;
    text-align: center;
    transform: rotate(5deg);
  }
}

.top-feature {
  height: 47px;
  width: 200px;
  padding-top: 7px;
  justify-content: center;
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 800px) {
  .top-feature {
    height: 47px;
    width: 100px;
    padding-top: 7px;
    justify-content: center;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.second-row {
  display: flex;
  justify-content: center;
  transform: rotate(5deg);
}

.left-feature {
  height: 47px;
  width: 200px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 800px) {
  .left-feature {
    height: 45px;

    width: 150px;
    padding-top: 7px;
    justify-content: center;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.right-feature {
  height: 47px;
  width: 200px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 800px) {
  .right-feature {
    height: 47px;
    width: 150px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.third-row {
  /* margin-left: 40%;
      margin-right: 40%; */
  display: flex;
  justify-content: center;
  text-align: center;
  transform: rotate(5deg);
}

.bottom-feature {
  height: 47px;
  width: 200px;
  margin-top: 32px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 800px) {
  .bottom-feature {
    height: 45px;

    width: 150px;
    padding-top: 10px;

    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

/* Curved Arrows for Mind Map */
.top-left-connection {
  margin-top: 20px;
  height: 60px;
  width: 60px;
  background-image: url(/public/assets/images/direction_1.png);
  background-repeat: no-repeat;
}

.top-right-connection {
  margin-top: 20px;
  height: 60px;
  width: 60px;
  background-image: url(/public/assets/images/direction_2.png);
  background-repeat: no-repeat;
}

.right-bottom-connection {
  height: 60px;
  width: 60px;
  background-image: url(/public/assets/images/direction_4.png);
  background-repeat: no-repeat;
}

.bottom-left-connection {
  height: 60px;
  width: 60px;
  background-image: url(/public/assets/images/direction_3.png);
  background-repeat: no-repeat;
}

.reason-details {
  margin-top: 68px;
  margin-left: 10%;
  margin-right: 10%;
  transform: rotate(5deg);
}

.reason-details div p {
  color: #000;
  text-align: justify;
  font-family: "Saira", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

@media (max-width: 800px) {
  .reason-details {
    margin-top: 68px;
    margin-left: 0%;
    margin-right: 0%;

    transform: rotate(6deg);
  }

  .reason-details div p {
    font-size: 18px !important;
    text-align: justify;
  }
}

.unimportant-details {
  color: #000;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.important-details {
  color: #c74907;
  font-family: "Saira", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

@media (max-width: 800) {
  .important-details {
    color: #c74907;
    font-family: "Saira", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  .unimportant-details {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@keyframes mymove {
  from {
    top: 0px;
  }

  to {
    top: 200px;
  }
}

/* ..........................Pinch Image Zoom............................ */
.curve-1 {
  height: 38rem;
  margin-left: 15%;
  margin-right: 17%;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  background-image: url(/public/assets/images/curve1.png);
  background-repeat: no-repeat;
  background-position: center;
}

.curve-2 {
  height: 38rem;
  margin-left: 15%;
  margin-right: 17%;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  background-image: url(/public/assets/images/curve2.png);
  background-repeat: no-repeat;
  background-position: center;
}

.scroll-container {
  display: flex;
  height: 2rem;
  margin-left: 15%;
  margin-right: 17%;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  border-color: white;
  background-image: url("/public/assets/images/youtube_thumnail.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 5px;
  border-radius: 30px;
}

.bg-exp {
  /* background: rgba(255, 235, 193, 0.5); */
  background-color: #fbe2ab10;
  /* margin-top: 200px; */
  /* filter: blur(250px); */
}

.bg-explanation {
  background-image: url(/public/assets/images/explanation.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-explanation2 {
  background-image: url(/public/assets/images/curve2.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 650px) {
  .bg-explanation {
    height: 300px;
  }

  .bg-explanation2 {
    height: 300px;
  }

  .bg-explanation img {
    width: 75%;
  }

  .bg-explanation2 img {
    width: 75%;
  }
}

@media only screen and (max-width: 1200px) {
  .bg-explanation img {
    width: 75%;
  }

  .bg-explanation2 img {
    width: 75%;
  }
}

/* ...............Blockchain for Social Impact......................... */
.social-sub-div-1 {
  margin-top: 40px;
  height: 50rem;
  border-radius: 45px;
  background-image: url(/public/assets/images/Rectangle-1.png);
  background-repeat: no-repeat;
  background-position: top;
}

.social-sub-div-2 {
  margin-top: 40px;
  height: 50rem;
  border-radius: 45px;
  background-image: url(/public/assets/images/Rectangle-2.png);
  background-repeat: no-repeat;
  background-position: top;
}

.social-sub-div-3 {
  margin-top: 40px;
  height: 51rem;
  border-radius: 45px;
  background-image: url(/public/assets/images/Rectangle-3.png);
  background-repeat: no-repeat;
  background-position: top;
}

.social-sub-contents {
  display: flex;
  margin-left: 9%;
  padding-right: 40px;
  padding-top: 4%;
}

.social-title-style {
  border-radius: 30px 0px 0px 30px;
  height: 84px;
  font-family: Lexend;
  font-size: 28px;
  padding-top: 26px;
  padding-left: 70px;
  padding-right: 70px;
  text-align: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.social-tile-details {
  height: 41.563rem;
  width: 59.125rem;
  border-radius: 45px;
  z-index: -1;
  transform: rotate(-10.17deg);
}

.social-tile-details-board {
  height: 45.313rem;
  width: 66.25rem;
  border-radius: 45px;
  border-radius: 45px;
  flex-shrink: 0;
}

.social-item-contents {
  display: flex;
  justify-content: space-between;
  margin-top: 13%;
  margin-left: 10%;
  margin-right: 10%;
}

.contents-text-style {
  margin-top: 30px;
  color: #262626;
  font-family: "Saira", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.spaceExtra {
  margin-bottom: 125px;
}

@media (max-width: 800px) {
  .social-sub-contents {
    display: block;
    margin-left: 32px;
    margin-right: 32px;
    justify-content: center;
  }

  .spaceExtra {
    margin-bottom: 20px;
  }
}

/* Blockchain explain again  */

.handleMenuBSocial {
  border-radius: 30px 0px 0px 30px;
  background: #161958;
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  padding: 25px 60px;
  cursor: pointer;
  /* margin-bottom: 25px; */
}

.handleMenuBSocial2 {
  border-radius: 30px 0px 0px 30px;
  background: transparent;
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  padding: 25px 60px;
  cursor: pointer;
}

.handleMenuBSocial2 p {
  color: #000;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.handleMenuBSocial p {
  color: #fff;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.box-div-BSocial {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 45px;
  /* background: #DCF1FF; */
  padding: 50px 30px;
  gap: 25px;
  position: relative;
  z-index: 999;
}

.box-div-BSocial p {
  color: #5b5b5b;
  font-family: "Saira", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  /* width: 50%; */
}

.spaceIssue {
  margin-top: 35px;
}

.handleImgBSocial {
  position: absolute;
  top: -20%;
  z-index: 1;
}

.bg-themeGen {
  /* border: 1px solid red; */
  width: 100%;
  /* background-image: url(/public/assets/images/themeGen.png); */
  min-height: 375px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 25px;
}

.text-themeGn {
  color: #5b5b5b;
  text-align: center;
  font-family: "Saira", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}

.reason-lists {
  display: block;
}

@media (max-width: 700px) {
  .reason-lists {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .handleImgBSocial {
    position: absolute;
    top: -10%;
    z-index: 1;
  }
}

.setpadding {
  padding-top: 100px;
}

@media (max-width: 700px) {
  .setpadding {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .bg-banner {
    height: auto !important;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 840px) {
  .bg-themeGen {
    width: 100%;
    /* background-image: url(/public/assets/images/themeGen.png); */
    min-height: 375px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 25px;
  }

  .text-themeGn {
    color: #5b5b5b;
    text-align: center;
    font-family: "Saira", sans-serif;
    font-size: 20px;
    margin-top: -20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
  }
}

@media only screen and (max-width: 1000px) {
  .handleImgBSocial {
    position: absolute;
    top: -2%;
    z-index: 1;
  }

  .spaceIssue {
    margin-top: 10px;
    margin-bottom: 80px;
    gap: 10px;
    justify-content: center;
  }

  .handleMenuBSocial {
    width: auto;
    padding: 28px 15px;
    border-radius: 30px;
  }

  .handleMenuBSocial2 {
    width: auto;
    padding: 28px 15px;
    border-radius: 30px;
  }

  .handleMenuBSocial2 p {
    font-size: 18px;
  }

  .handleMenuBSocial p {
    font-size: 18px;
  }
}

/* ................................Features of ZecureChain Styles................................. */

.feature-heading-style {
  color: #000;
  font-family: "Lexend", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}