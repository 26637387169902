.title-style {
  color: #000;
  text-align: center;
  /* margin-left: 20%;
  margin-right: 20%; */
  font-family: "Lexend", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sub-title-style {
  color: #545454;
  font-family: "Saira", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subscriptions-box {
  display: flex;
  justify-content: center;
  border-radius: 81px;
  background-color: #d6dbfb;
}

.tof-subscription {
  display: flex;
  justify-content: space-between;
}

.style-sub {
  text-align: center;
  font-family: "Lexend" sans-serif;
  font-size: 18px;
  font-style: normal;
  border-radius: 40px;
  padding: 15px;
  font-weight: 400;
  line-height: normal;
}



.item-box {
  display: flex;
  justify-content: center;
  border-radius: 81px;
  background-color: #fff9d4;
}

.purchase-types {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-bottom: 150px;
}

@media (max-width: 700px) {
  .checkout-box-style {
    width: 90% !important;
  }

  .purchase-types {
    margin-top: 50px;
    display: block;
  }

  .purchase-detail {
    margin-top: 20px;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 12px;
    background-color: #f3f4fb;
    border: 1px solid #161958;
  }
}

.purchase-detail {
  border-radius: 12px;
  background-color: #f3f4fb;
  border: 1px solid #161958;
}

.price-style {
  text-align: center;
  font-family: "Lexend", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 700px) {
  .purchase-detail {
    border-radius: 12px;
    background-color: #f3f4fb;
    border: 1px solid #161958;
  }
}

.get-started-design {
  margin-top: 32px;
  height: 38px;
  padding: 4px;
  border-radius: 30px;
  color: #161958;
  text-align: center;
  margin-left: 10%;
  margin-right: 7%;
}

.get-started-design:hover {
  cursor: pointer;
}

.purchase-heading {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 80px;
  padding-right: 80px;
  text-align: center;
  font-family: "Lexend", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subscriptions-spec {
  padding-top: 38px;
  padding-bottom: 30px;
  padding-right: 20px;
  color: #545454;
  text-align: center;
  font-family: "Lexend", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subscriptions-spec:hover {
  padding-bottom: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: radial-gradient(50% 50% at 50% 50%, #2d37a6 0%, #1a1e65 100%);
  padding-right: 20px;
  color: white;
  text-align: center;
  font-family: "Lexend", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.per-sub-style {
  color: #000;
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Checkout Page Designssss */

.goBackStyle {
  color: #000;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.checkout-box-style {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-top: 35px;
  margin-bottom: 50px;
  border-radius: 18px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Lexend", sans-serif !important;
  /* border: 1px solid #2a339b; */
  border: none;
  background: #e5e9ff;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
}

.chk-title-style {
  color: #000;
  font-family: "Lexend", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.chk-price-style {
  font-family: "Lexend", sans-serif;
  font-size: 34px;
  font-style: normal;

  font-weight: 400;
  line-height: normal;
}

.radio-bg {
  margin-top: 10px;
  height: 60px;
  width: 100%;
  border-radius: 16px;
  background-color: white;
}

/* input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px; 
  top: -2px;
  left: -1px;
  position: relative;
  background-color: black;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */