.aboutUs-bg {
  background-image: url(/public/assets/images/about-us-bg.png);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  padding-bottom: 30px;
}

.bannerPartAbout {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.heading-style {
  padding-top: 80px;
  color: #fff;
  font-family: Lexend;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sub-heading-style {
  color: #fff;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  text-align: justify;
  width: 40%;
}

.style1 {
  color: #000;
  font-family: Lexend;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.style2 {
  color: #545454;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.aboutUs-details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.parentAboutSections div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
}

.parentAboutSections div div {
  width: 100%;
}

.parentAboutSections div p {
  color: #545454;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
  text-align: justify;
}

@media only screen and (max-width: 700px) {
  .sub-heading-style {
    width: 80%;
  }
}

@media (max-width: 992px) {
  .parentAboutSections div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;

  }

  .aboutUs-details {
    display: block;
  }

  .team-members {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .team-members div {
    margin-bottom: 15px;
  }
}

.detail-style {
  color: #545454;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
  /* 287.5% */
}

.teams-box {
  margin-top: 36px;
  margin-bottom: 50px;
  height: auto;
  width: auto;
  background-color: #fcda27;
  padding-top: 7%;
  padding-bottom: 7%;
}

.team-members {
  display: flex;
  justify-content: center;
  align-items: center;
}


.our-teams-style {
  color: #000;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.team-member-position {
  color: #000;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hidden {
  display: none;
}

.set-avatar {
  height: 250px;
  width: 250px;

}