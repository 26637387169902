.searchInputBar {
    animation: glowing 1.5s infinite;
    /* box-shadow: 0 0 10px #80808080; */
}

/* Glow animation */
@keyframes glowing {
    0% {
        box-shadow: 0 0 10px #80808080;
    }

    50% {
        box-shadow: 0 0 20px #80808080, 0 0 30px #80808080;
    }

    100% {
        box-shadow: 0 0 10px #80808080;
    }
}