.box-tab-signup {
    /* width: 60%; */
}

.handleSignupImg {
    width: 100%;
}

.input-group-textCusReg {
    display: flex !important;
    align-items: center !important;
    border-radius: 48px !important;
    background: #414791 !important;
    color: #F3F4FB !important;
    font-family: Lexend !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    margin-bottom: 0 !important;
    height: 37px !important;
    padding: 15px !important;
    cursor: pointer !important;
}



.handleInputInd input {
    /* width: 100%; */

}

.titleOfAuth {
    color: #000;
    text-align: center;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}



.sign-up-bg {
    border-radius: 11px;
    background: #D6DBFB;
    padding: 30px 30px;
}

@media only screen and (max-width: 1200px) {
    .handleSignupImg {
        width: 450px;
    }
}

@media only screen and (max-width: 650px) {
    .box-tab-signup {
        width: 100%;
    }

    /* .sign-up-bg {

        width: 100% !important;
    } */
}

@media only screen and (min-width: 2500px) {
    .handleFor4k {
        height: 86vh !important;
    }

    .handleFor4k div {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
}