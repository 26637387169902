.cardLoginPassword {
    width: 90%;
}

@media only screen and (max-width: 650px) {
    .cardLoginPassword {
        width: 100%;
    }
}

@media only screen and (min-width: 1200px) {
    .cardLoginPassword {
        width: 500px;
    }
}