.navbarFont {
    color: #FFF;
    font-family: 'Lexend', sans-serif;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* word-spacing: 2rem !important; */
}

.nav-link {
    color: white !important;
    font-size: 16px;
}

.nav-link:hover {
    color: #eccd1a !important;
}

.hr {
    color: #AEABAB;
    border: 1;
    opacity: 1;
    margin-left: 5%;
    margin-right: 5%;

}



.logo-width {
    width: 25%;
}

.logo-width2 {
    width: 25%;
}

.bootstrap-custom-dropdown-header div {
    border-radius: 6px;
    background: #D6DBFB;
    color: #000;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.bootstrap-custom-dropdown-header div a {

    background-image: url(/public/assets/images/CaretRight.png);
    background-position: right center;
    background-repeat: no-repeat;
}

.bootstrap-custom-dropdown-header div a:hover {
    background-image: url(/public/assets/images/CaretRight.png);
    background-position: right center;
    background-repeat: no-repeat;
    background-color: transparent;
    color: darkgoldenrod;
}

.bootstrap-custom-dropdown-header div a:active {

    background: #D6DBFB;
    color: #000;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

@media only screen and (max-width: 769px) {
    .logo-width {
        width: 60%;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .for-logo-width {
        width: 60%;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .logo-width2 {
        width: 50%;
    }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
    .nav-link {
        font-size: 1.1rem !important;
    }

    .for-logo-width {
        width: 170px;
    }

}