.cardForFolder {
    display: flex;
    width: 85%;
    height: 60px;
    padding: 10px 15px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    background: #E8E8E8;
    flex-shrink: 0;
}

.cardForFolder p {
    color: #5B5B5B;
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}

.folder-input {
    width: 100%;
    height: 45px;
    padding: 10px;
    font-size: 1rem;
    border-radius: 48px;
    background: #C2E7FF;
    border: none;
    outline: none;
    margin-bottom: 10px;
}

@media only screen and (min-width: 1301px) {
    .cardForFolder {
        width: 80%;
    }
}

@media only screen and (max-width: 650px) {
    .cardForFolder {
        width: 100%;
        padding: 10px 10px;
    }

    .cardForFolder p {
        font-size: 14px;
    }
}