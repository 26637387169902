.issue-style {
  color: #1e1e1e;
  font-family: "Lexend", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.name-style {
  color: #202576;
  font-family: "Lexend", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.name-style:hover {
  cursor: pointer;
}