.cardLogin {
    width: fit-content;
    height: auto;
    /* flex-shrink: 0; */
    border-radius: 11px;
    background: #D6DBFB;
    padding: 30px 30px;
}

.titleOfAuth {
    color: #000;
    text-align: center;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.imgSlidesRound {
    animation: circles 5s linear infinite;

}

.delayed {
    animation-delay: 15s;
    animation-fill-mode: both;
}

@keyframes circles {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

.forCssLogin label {
    color: #333333;
    text-align: center;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
}

.forCssLogin input {
    width: 95%;
    height: 40px;
    padding: 10px;
    font-size: 1rem;
    border-radius: 48px;
    border: 1px solid #7C89E4;
    /* background: #F3F4FB; */
    margin-bottom: 10px;
    outline: #D6DBFB;
}

.input-group-text {
    height: 40px;
    padding: 10px;
    font-size: 1rem;
    border-radius: 48px;
    border: 1px solid #7C89E4;
    /* background: #F3F4FB; */
    margin-bottom: 10px;
    outline: #D6DBFB;
}

.forCssLogin select {
    width: 95%;
    height: 40px;
    padding-left: 10px;
    border-radius: 48px;
    border: 1px solid #7C89E4;
    outline: #D6DBFB;
    font-size: 1rem;
    color: black;
    font-family: 'Saira', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
}

.text-info-login {
    color: #5B5B5B;
    font-family: 'Saira', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.text-info-login span {
    color: #1F4075;
    font-family: 'Saira', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media only screen and (max-width: 650px) {
    .cardLogin {
        width: 100%;
    }
}

@media only screen and (min-width: 1200px) {
    .cardLogin {
        width: 540px;
    }
}