.cardDocsFolder {
    width: 280px;
    height: 182px;
    flex-shrink: 0;
    border-radius: 9px;
    background: #E5E5E5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.cardDocsFolder p {
    color: #000;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.size-div {
    width: 80%;
}

.upload-div {
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 3px dashed #024F83;
    margin-top: 25px;
}

.upload-div:hover {
    border: 3px dashed #272a2c;
}

.upload-space h4 {
    color: #000;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.upload-space p {
    color: #5B5B5B;
    font-family: 'Saira', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.doc-progress {
    height: 100px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #024F83;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
}

.f-child {
    /* border: 2px solid red; */
    width: 70px;
}

.s-child {
    /* border: 2px solid red; */
    width: 100%;
}

.t-child {
    /* border: 2px solid red; */
    width: 70px;
    text-align: center;
}

.titleDoc {
    color: #000;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

@media only screen and (max-width: 650px) {
    .cardDocsFolder {
        margin-left: auto;
        margin-right: auto;
    }

    .upload-div {
        height: 250px;
    }

    .size-div {
        width: 90%;
    }

    .handleDocsText {
        font-size: 12px;
    }
}