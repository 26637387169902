.cardFonts {
    font-family: "Lexend", sans-serif;
}

.blogSingleCard {
    width: 75%;
}

.singleHeadTitle {
    font-family: "Lexend", sans-serif;
    color: #454798;
    margin-bottom: 16px;
    padding-top: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 60px
}

@media only screen and (max-width: 991px) {
    .blogSingleCard {
        width: 100%;
    }

    .singleHeadTitle {
        font-family: "Lexend", sans-serif;
        color: #454798;
        margin-bottom: 16px;
        padding-top: 0;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px
    }
}