@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');

/* @font-face {
  font-family: 'Saira';
  src: local('Saira'),
    url('./fonts/static/Saira-Regular.ttf') format('woff'),
} */

@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');

/* @font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lexend/v19/wlpwgwvFAVdoq2_v9KQU4Wc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;

} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Lexend', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #F3F6F9 !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-family: 'Lexend', sans-serif !important;
}

.btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.navbar-toggler {
  background-color: #F3F6F9 !important;
}

.react-tel-input .selected-flag.open:before {
  border-color: none !important;
  box-shadow: none !important;
}

.react-tel-input {
  font-family: Lexend !important;
}


.react-tel-input .selected-flag:before {
  border: none !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: none !important;
  box-shadow: none !important;
}

.phone-sizing {
  width: 100%;
  height: 50px;
  padding: 5px;
  font-size: 1rem;
  border-radius: 48px;
  border: 1px solid #7C89E4;
  background: #F3F4FB;
  /* margin-bottom: 10px; */
  outline: #D6DBFB;
}

.css-1e9lk82-MuiListItemAvatar-root {
  min-width: 40px !important;
  width: 40px !important;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  background: radial-gradient(50% 50.00% at 50% 50.00%, #2D37A6 0%, #161958 100%) !important;
  color: white;
}

.css-1ab2xsx {
  background: radial-gradient(50% 50.00% at 50% 50.00%, #2D37A6 0%, #161958 100%) !important;
  color: white;
}