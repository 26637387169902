.div-otp {
    width: 40%;
    height: 350px;
    flex-shrink: 0;
    border-radius: 11px;
    background: #D6DBFB;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 992px) {
    .div-otp {
        width: 100%;
        height: 350px;
        padding: 0px 25px;
    }
}

@media only screen and (min-width:993px) and (max-width: 1200px) {
    .div-otp {
        width: 50%;
    }
}