.blog-section {
  display: flex;
  justify-content: center;
  gap: 30px;

}

@media only screen and (max-width:1000px) {
  .blog-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
}

.blog-section:hover {
  cursor: pointer;
}

.blog-bg {
  height: 335px;
  width: 100%;
}


.blog-title {
  color: #000;
  font-family: "Lexend", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog-sub {
  margin-top: 12px;
  color: #545454;
  font-family: "Lexend", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.posted-by {
  color: #161616;
  font-family: "Lexend", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.posted-on {
  color: #545454;

  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.time {
  color: #919191;

  font-family: Lexend;
  /* font-size: 12px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog-details-head {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width:700px) {
  .blog-details-head {
    display: block;

  }

}

.sub-heading-title {
  font-weight: 600;
  margin-top: 2%;
}

@media (max-width:800px) {
  .sub-heading-title {
    margin-top: 4%;
  }

  .blog-detail-img {
    height: 264px !important;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 15%;
    padding-right: 15%;
  }
}

.blog-main-content {
  margin-top: 2%;
  color: #545454;
  font-family: "Lexend", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
}

.blog-detail-img {
  margin-top: 5%;
  height: 364px;
  display: flex;
  justify-content: center;
}